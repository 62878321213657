@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@900&family=Public+Sans:wght@400;500;600;700&display=swap");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
  background: none;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input {
  appearance: none;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

a,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.icon-arrow-prev {
  width: 1em;
  height: 1em;
  fill: #FAFBFF;
}

.icon-arrow-right {
  width: 0.57em;
  height: 1em;
  fill: #FAFBFF;
}

.icon-check {
  width: 1em;
  height: 1em;
  fill: #FAFBFF;
}

.icon-email {
  width: 1.22em;
  height: 1em;
  fill: #FAFBFF;
}

.icon-facebook {
  width: 0.59em;
  height: 1em;
  fill: #FAFBFF;
}

.icon-instagram {
  width: 1em;
  height: 1em;
  fill: #FAFBFF;
}

.icon-lock {
  width: 0.91em;
  height: 1em;
  fill: #FAFBFF;
}

.icon-twitter {
  width: 1.22em;
  height: 1em;
  fill: #FAFBFF;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity,transform;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity,transform;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0);
}

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

html.no-scroll {
  overflow: hidden;
}

body {
  min-width: 375px;
  background: #FAFBFF;
  font-family: 'Public Sans', sans-serif;
  font-size: 16px;
  letter-spacing: -.015em;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #00006D;
}

body.dark {
  background: #14141B;
  color: #F7FAFC;
}

body.no-scroll {
  overflow: hidden;
}

video {
  width: 100%;
  max-height: 700px;
}

button,
input,
textarea,
select {
  font-family: 'Public Sans', sans-serif;
}

.page {
  overflow: hidden;
}

.center {
  max-width: 1332px;
  margin: 0 auto;
  padding: 0 42px;
}

@media only screen and (max-width: 1023px) {
  .center {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .center {
    padding: 0 32px;
  }
}

a.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.btn {
  min-width: 154px;
  height: 64px;
  padding: 0 32px;
  border-radius: 32px;
  font-family: 'Public Sans', sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -.01em;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .btn {
    height: 56px;
    border-radius: 28px;
    font-size: 16px;
  }
}

.btn .icon, .btn__text {
  display: inline-block;
  vertical-align: middle;
}

.btn .icon-arrow-right {
  margin: 0 30px 0 6px;
  font-size: 21px;
}

@media only screen and (max-width: 767px) {
  .btn .icon-arrow-right {
    margin: 0 20px 0 0;
    font-size: 16px;
  }
}

.btn_purple {
  background: #7737FF;
  color: #F7FAFC;
}

.btn_purple .icon {
  fill: #fff;
}

.btn_purple:hover {
  background: #6E34EA;
}

.btn_border {
  padding: 0 30px;
  border: 2px solid rgba(119, 55, 255, 0.24);
  color: #7737FF;
}

.btn_border:hover {
  border-color: #7737FF;
}

.btn_red {
  background: #FF570F;
  color: #F7FAFC;
}

.btn_red:hover {
  background: #FF5207;
}

.btn_green {
  background: #52cb95;
  color: #F7FAFC;
}

.btn_green:hover {
  background: #459836;
}

.btn_sm {
  height: 48px;
  border-radius: 24px;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .btn_sm {
    height: 48px;
    border-radius: 24px;
  }
}

.header {
  position: relative;
  z-index: 10;
  margin-bottom: 24px;
  padding: 48px 0;
  letter-spacing: 0;
}

@media only screen and (max-width: 1023px) {
  .header {
    margin-bottom: 40px;
    padding: 32px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header {
    margin-bottom: 24px;
  }
}

.header__center {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .header__center {
    padding: 0 48px;
  }
}

@media only screen and (max-width: 1023px) {
  .header__center {
    padding: 0 40px;
  }
}

.header__logo {
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .header__logo {
    position: relative;
    z-index: 15;
    margin-right: auto;
  }
}

.header__logo .header__pic {
  width: 100px;
}

.header__logo .header__pic_white {
  display: none;
}

.header__wrap {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 96px;
}

@media only screen and (max-width: 1023px) {
  .header__wrap {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    min-height: 100vh;
    padding: 152px 40px 72px;
    background: #FAFBFF;
    transform: translateX(100%);
    transition: transform .3s;
  }
  .header__wrap.visible {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 767px) {
  .header__wrap {
    padding: 110px 32px 56px;
  }
}

@media only screen and (max-width: 474px) {
  .header__wrap {
    padding-top: 136px;
  }
}

.header__nav {
  display: flex;
  margin-right: auto;
}

@media only screen and (max-width: 1023px) {
  .header__nav {
    flex-direction: column;
    margin: 0 0 auto;
  }
}

@media only screen and (max-width: 767px) {
  .header__nav {
    flex-direction: row;
  }
}

@media only screen and (max-width: 474px) {
  .header__nav {
    flex-direction: column;
  }
}

.header__link {
  position: relative;
  font-weight: 700;
  color: #4C5D77;
  transition: color .25s;
}

@media only screen and (max-width: 1023px) {
  .header__link {
    padding-right: 40px;
    font-family: 'Merriweather', serif;
    font-size: 36px;
    line-height: 1.55556;
    font-weight: 900;
    color: #00006D;
  }
  .header__link:after {
    content: "";
    position: absolute;
    top: 16px;
    right: 6px;
    width: 12px;
    height: 21px;
    background: url("data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C-0.0976315 0.683417 -0.0976315 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976315 12.6834 -0.0976315 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7.70711 7.70711C8.09763 7.31658 8.09763 6.68342 7.70711 6.29289L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893Z' fill='%237737FF'/%3E%3C/svg%3E%0A") no-repeat 50% 50%/100% auto;
  }
}

@media only screen and (max-width: 767px) {
  .header__link {
    font-size: 24px;
    line-height: 1.66667;
  }
  .header__link:after {
    top: 13px;
    width: 8px;
    height: 14px;
  }
}

.header__link:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2px;
  width: 0;
  height: 1px;
  background: #52cb95;
  transition: width .25s;
}

@media only screen and (max-width: 1023px) {
  .header__link:before {
    display: none;
  }
}

.header__link:hover {
  color: #52cb95;
}

.header__link:hover:before {
  width: 100%;
}

.header__link:not(:last-child) {
  margin-right: 48px;
}

@media only screen and (max-width: 1023px) {
  .header__link:not(:last-child) {
    margin: 0 0 48px;
  }
}

@media only screen and (max-width: 767px) {
  .header__link:not(:last-child) {
    margin: 0 32px 0 0;
  }
}

@media only screen and (max-width: 474px) {
  .header__link:not(:last-child) {
    margin: 0 0 48px;
  }
}

.header__control {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1023px) {
  .header__control {
    flex-direction: column-reverse;
    margin-top: 24px;
  }
}

.header__login {
  display: inline-flex;
  align-items: center;
  margin-right: 36px;
}

@media only screen and (max-width: 1023px) {
  .header__login {
    margin: 24px 0 0;
  }
}

.header__login .icon {
  margin-left: 20px;
  font-size: 14px;
  fill: #52cb95;
}

.header__login .header__text {
  position: relative;
  font-weight: 700;
  color: #4C5D77;
  transition: color .25s;
}

.header__login .header__text:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2px;
  width: 0;
  height: 1px;
  background: #52cb95;
  transition: width .25s;
}

.header__login:hover .header__text {
  color: #52cb95;
}

.header__login:hover .header__text:before {
  width: 100%;
}

.header__btn {
  height: 56px;
  border-radius: 28px;
  font-size: 16px;
}

@media only screen and (max-width: 1023px) {
  .header__btn {
    min-width: 100%;
  }
}

.header__burger {
  display: none;
  position: relative;
  z-index: 12;
  width: 48px;
  height: 48px;
  padding: 10px;
  background: #52cb95;
  border-radius: 50%;
  font-size: 0;
  transition: opacity .25s;
}

@media only screen and (max-width: 1023px) {
  .header__burger {
    display: inline-block;
  }
}

.header__burger:before, .header__burger:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 2px;
  background: #fff;
  border-radius: 2px;
  transition: transform .25s;
}

.header__burger:before {
  margin-bottom: 3px;
}

.header__burger:after {
  margin-top: 3px;
}

.header__burger.active:before {
  transform: translateY(4px) rotate(45deg);
}

.header__burger.active:after {
  transform: translateY(-4px) rotate(-45deg);
}

.section:not(:last-child) {
  margin-bottom: 144px;
}

@media only screen and (max-width: 1365px) {
  .section:not(:last-child) {
    margin-bottom: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .section:not(:last-child) {
    margin-bottom: 104px;
  }
}

.title {
  margin-bottom: 32px;
  font-family: 'Merriweather', serif;
  font-size: 36px;
  line-height: 1.55556;
  font-weight: 900;
  letter-spacing: -.01em;
}

@media only screen and (max-width: 767px) {
  .title {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 1.66667;
  }
}

.title__link {
  border-bottom: 3px solid #52cb95;
  color: #52cb95;
  transition: border-color .25s;
}

@media only screen and (max-width: 767px) {
  .title__link {
    border-width: 2px;
  }
}

.title__link:hover {
  border-color: transparent;
}

.title_sm {
  font-size: 24px;
  line-height: 1.66667;
}

.title_big {
  font-size: 48px;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .title_big {
    font-size: 36px;
    line-height: 1.55556;
  }
}

.info {
  font-size: 24px;
  line-height: 1.66667;
  color: #4C5D77;
}

@media only screen and (max-width: 767px) {
  .info {
    font-size: 20px;
    line-height: 2;
  }
}

.main {
  position: relative;
  padding: 93px 0;
}

@media only screen and (max-width: 1365px) {
  .main {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 1199px) {
  .main {
    margin-bottom: 120px;
    padding: 0;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .main {
    margin-bottom: 104px;
  }
}

.main__wrap {
  max-width: 576px;
}

@media only screen and (max-width: 1199px) {
  .main__wrap {
    max-width: 704px;
    margin: 0 auto 72px;
  }
}

@media only screen and (max-width: 767px) {
  .main__wrap {
    margin-bottom: 48px;
  }
}

.main__info {
  margin-bottom: 64px;
  font-size: 24px;
  line-height: 1.66667;
}

@media only screen and (max-width: 767px) {
  .main__info {
    margin-bottom: 48px;
  }
}

.main__btns {
  display: flex;
}

@media only screen and (max-width: 1199px) {
  .main__btns {
    justify-content: center;
  }
}

@media only screen and (max-width: 639px) {
  .main__btns {
    display: block;
  }
}

@media only screen and (max-width: 639px) {
  .main__btn {
    min-width: 100%;
  }
}

.main__btn:not(:last-child) {
  margin-right: 32px;
}

@media only screen and (max-width: 639px) {
  .main__btn:not(:last-child) {
    margin: 0 0 24px;
  }
}

.main__preview {
  position: absolute;
  top: 0;
  right: calc(50% - 900px);
  pointer-events: none;
  font-size: 0;
  opacity: 0;
  transform: translateX(100px);
  animation: image 1s forwards;
}

@media only screen and (max-width: 1365px) {
  .main__preview {
    right: calc(50% - 800px);
  }
}

@media only screen and (max-width: 1199px) {
  .main__preview {
    position: static;
  }
}

@media only screen and (max-width: 1365px) {
  .main__pic {
    width: 770px;
  }
}

@media only screen and (max-width: 1199px) {
  .main__pic {
    width: 848px;
  }
}

@media only screen and (max-width: 1023px) {
  .main__pic {
    width: 100%;
    max-width: 768px;
  }
}

@media only screen and (max-width: 1365px) {
  .main__video {
    max-height: 700px;
  }
}

@media only screen and (max-width: 1199px) {
  .main__video {
    max-height: 700px;
  }
}

@media only screen and (max-width: 1023px) {
  .main__video {
    max-height: 700px;
  }
}

@keyframes image {
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.quality__center {
  max-width: 960px;
}

.quality__head {
  margin-bottom: 80px;
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .quality__head {
    margin-bottom: 72px;
  }
}

@media only screen and (max-width: 767px) {
  .quality__head {
    margin-bottom: 56px;
  }
}

.quality__list {
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .quality__list {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -48px;
  }
}

@media only screen and (max-width: 767px) {
  .quality__list {
    display: block;
    max-width: 240px;
    margin: 0 auto;
  }
}

.quality__item {
  display: flex;
  align-items: center;
  letter-spacing: 0;
  font-weight: 700;
}

@media only screen and (max-width: 1023px) {
  .quality__item {
    justify-content: center;
    flex: 0 0 50%;
    width: 50%;
    margin-top: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .quality__item {
    justify-content: flex-start;
    width: 100%;
    margin: 0;
  }
}

.quality__item:first-child .quality__icon {
  background: rgba(32, 218, 241, 0.08);
}

.quality__item:nth-child(2) .quality__icon {
  background: rgba(255, 153, 0, 0.08);
}

.quality__item:nth-child(3) .quality__icon {
  background: rgba(255, 87, 15, 0.08);
}

.quality__item:not(:last-child) {
  margin-right: auto;
}

@media only screen and (max-width: 1023px) {
  .quality__item:not(:last-child) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .quality__item:not(:last-child) {
    margin: 0 0 48px;
  }
}

.quality__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  margin-right: 24px;
  border-radius: 50%;
  font-size: 0;
}

.quality__pic {
  max-width: 100%;
  max-height: 100%;
}

.details__center {
  max-width: 1236px;
}

.details__item {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .details__item:first-child .details__pic {
    width: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .details__item:nth-child(2) .details__pic {
    width: 630px;
  }
}

@media only screen and (max-width: 767px) {
  .details__item:nth-child(3) .details__pic {
    width: 630px;
  }
}

@media only screen and (max-width: 1199px) {
  .details__item:nth-child(2n+1) {
    flex-direction: column;
  }
}

.details__item:nth-child(2n+1) .details__preview {
  margin-right: auto;
}

@media only screen and (max-width: 1199px) {
  .details__item:nth-child(2n+1) .details__preview {
    margin: 0 auto 72px;
  }
}

@media only screen and (max-width: 767px) {
  .details__item:nth-child(2n+1) .details__preview {
    margin-bottom: 56px;
  }
}

.details__item:nth-child(2n+1) .details__box[data-aos] {
  transform: translateX(100px) skewX(-20deg);
  opacity: 0;
  transition-property: transform, opacity;
}

.details__item:nth-child(2n+1) .details__box[data-aos].aos-animate {
  transform: translateX(0) skewX(0deg);
  opacity: 1;
}

@media only screen and (max-width: 1199px) {
  .details__item:nth-child(2n) {
    flex-direction: column-reverse;
  }
}

.details__item:nth-child(2n) .details__preview {
  margin-left: auto;
}

@media only screen and (max-width: 1199px) {
  .details__item:nth-child(2n) .details__preview {
    margin: 0 auto 72px;
  }
}

@media only screen and (max-width: 767px) {
  .details__item:nth-child(2n) .details__preview {
    margin-bottom: 56px;
  }
}

.details__item:nth-child(2n) .details__box[data-aos] {
  transform: translateX(-100px) skewX(20deg);
  opacity: 0;
  transition-property: transform, opacity;
}

.details__item:nth-child(2n) .details__box[data-aos].aos-animate {
  transform: translateX(0) skewX(0deg);
  opacity: 1;
}

.details__item:not(:last-child) {
  margin-bottom: 144px;
}

@media only screen and (max-width: 1365px) {
  .details__item:not(:last-child) {
    margin-bottom: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .details__item:not(:last-child) {
    margin-bottom: 56px;
  }
}

.details__preview {
  position: relative;
  flex-shrink: 0;
  width: 528px;
  height: 528px;
  border-radius: 50%;
  overflow: hidden;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .details__preview {
    width: 310px;
    height: 310px;
  }
}

.details__icon {
  position: absolute;
}

.details__box {
  max-width: 480px;
}

@media only screen and (max-width: 1199px) {
  .details__box {
    max-width: 528px;
    text-align: center;
  }
}

@media only screen and (max-width: 1023px) {
  .details__box {
    max-width: 100%;
    text-align: left;
  }
}

.details__info {
  margin-bottom: 48px;
}

@media only screen and (max-width: 767px) {
  .details__info {
    margin-bottom: 40px;
  }
}

.details__link {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  color: #52cb95;
}

.details__link .icon {
  margin-left: 20px;
  font-size: 14px;
  fill: #52cb95;
  transition: transform .25s;
}

.details__link:hover .icon {
  transform: translateX(3px);
}

.system__head {
  max-width: 710px;
  margin: 0 auto 10px;
  text-align: center;
}

@media only screen and (max-width: 1365px) {
  .system__head {
    margin-bottom: 72px;
  }
}

@media only screen and (max-width: 767px) {
  .system__head {
    margin-bottom: 56px;
  }
}

.system__preview {
  height: 700px;
  margin-right: -110px;
  text-align: center;
  font-size: 0;
}

@media only screen and (max-width: 1365px) {
  .system__preview {
    height: 660px;
  }
}

@media only screen and (max-width: 1023px) {
  .system__preview {
    height: 407px;
    margin-right: -60px;
  }
}

@media only screen and (max-width: 767px) {
  .system__preview {
    height: 238px;
    margin-right: -10px;
  }
}

.system__preview[data-aos] {
  transform: translateY(100px);
  opacity: 0;
  transition-property: transform, opacity;
}

.system__preview[data-aos].aos-animate {
  transform: translateY(0);
  opacity: 1;
}

.system__pic {
  max-width: 100%;
}

@media only screen and (max-width: 1365px) {
  .system__pic {
    max-width: 940px;
  }
}

@media only screen and (max-width: 1023px) {
  .system__pic {
    max-width: 576px;
  }
}

@media only screen and (max-width: 767px) {
  .system__pic {
    max-width: 340px;
  }
}

.review__center {
  display: flex;
}

@media only screen and (max-width: 1199px) {
  .review__center {
    display: block;
    text-align: center;
  }
}

.review__title {
  max-width: 488px;
  margin: 0 auto 0 0;
}

@media only screen and (max-width: 1199px) {
  .review__title {
    max-width: 704px;
    margin: 0 auto 72px;
  }
}

@media only screen and (max-width: 767px) {
  .review__title {
    margin-bottom: 56px;
  }
}

.review__container {
  flex-shrink: 0;
  width: 616px;
}

@media only screen and (max-width: 1199px) {
  .review__container {
    width: 488px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 767px) {
  .review__container {
    width: 100%;
  }
}

.review__item {
  display: flex;
}

@media only screen and (max-width: 1199px) {
  .review__item {
    display: block;
  }
}

.review__ava {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  margin-right: 48px;
  border-radius: 50%;
  overflow: hidden;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .review__ava {
    margin: 0 auto 48px;
  }
}

@media only screen and (max-width: 767px) {
  .review__ava {
    width: 64px;
    height: 64px;
    margin-bottom: 24px;
  }
}

.review__pic {
  min-height: 100%;
}

.review__details {
  flex-grow: 1;
}

.review__blockquote {
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 1.66667;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .review__blockquote {
    font-size: 20px;
    line-height: 1.6;
  }
}

.review__info {
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 2;
  color: #4C5D77;
}

.review__author {
  font-weight: 500;
  color: #FB5392;
}

.review .owl-dots {
  display: flex;
  margin-top: 48px;
  padding-left: 127px;
}

@media only screen and (max-width: 1199px) {
  .review .owl-dots {
    justify-content: center;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .review .owl-dots {
    margin-top: 40px;
  }
}

.review .owl-carousel button.owl-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #FAFBFF;
  background: #52cb95;
  transition: all .25s;
}

.review .owl-carousel button.owl-dot:hover {
  opacity: .7;
}

.review .owl-carousel button.owl-dot.active {
  border-color: #52cb95;
  background: transparent;
  opacity: 1;
}

.review .owl-carousel button.owl-dot:not(:last-child) {
  margin-right: 15px;
}

.indicators__center {
  max-width: 856px;
}

.indicators__head {
  max-width: 704px;
  margin: 0 auto 144px;
  text-align: center;
}

@media only screen and (max-width: 1365px) {
  .indicators__head {
    margin-bottom: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .indicators__head {
    margin-bottom: 104px;
  }
}

.indicators__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -80px;
}

@media only screen and (max-width: 1023px) {
  .indicators__list {
    display: block;
    text-align: center;
    margin-top: 0;
  }
}

.indicators__item {
  min-width: 308px;
  margin-top: 80px;
}

@media only screen and (max-width: 1023px) {
  .indicators__item {
    max-width: 100%;
    margin-top: 0;
  }
}

.indicators__item:nth-child(2n+1) {
  margin-right: auto;
}

.indicators__item:first-child .indicators__icon {
  background: rgba(82, 203, 149, 0.08);
}

.indicators__item:first-child .indicators__counter {
  color: #52cb95;
}

.indicators__item:nth-child(2) .indicators__icon {
  background: rgba(251, 83, 146, 0.08);
}

.indicators__item:nth-child(2) .indicators__counter {
  color: #FB5392;
}

.indicators__item:nth-child(3) .indicators__icon {
  background: rgba(255, 153, 0, 0.08);
}

.indicators__item:nth-child(3) .indicators__counter {
  color: #FF9900;
}

.indicators__item:nth-child(4) .indicators__icon {
  background: rgba(82, 203, 149, 0.08);
}

.indicators__item:nth-child(4) .indicators__counter {
  color: #52cb95;
}

@media only screen and (max-width: 1023px) {
  .indicators__item:not(:last-child) {
    margin-bottom: 72px;
  }
}

@media only screen and (max-width: 767px) {
  .indicators__item:not(:last-child) {
    margin-bottom: 56px;
  }
}

.indicators__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  border-radius: 50%;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .indicators__icon {
    margin: 0 auto 32px;
  }
}

.indicators__pic {
  max-width: 100%;
  max-height: 100%;
}

.indicators__counter {
  margin-bottom: 24px;
  font-family: 'Merriweather', serif;
  font-size: 48px;
  line-height: 1.5;
  font-weight: 900;
}

@media only screen and (max-width: 767px) {
  .indicators__counter {
    margin-bottom: 16px;
    font-size: 36px;
    line-height: 1.55556;
  }
}

.trial__wrap {
  display: flex;
  align-items: center;
  padding: 104px 96px;
  border-radius: 56px;
  background: #F3F6FF url("../img/pattern.png") no-repeat 50% 50%/cover;
}

@media only screen and (max-width: 1365px) {
  .trial__wrap {
    padding: 72px 48px;
  }
}

@media only screen and (max-width: 1023px) {
  .trial__wrap {
    display: block;
    padding: 0;
    background: none;
    text-align: center;
  }
}

.trial__box {
  max-width: 670px;
  margin-right: auto;
}

@media only screen and (max-width: 1199px) {
  .trial__box {
    max-width: 490px;
  }
}

@media only screen and (max-width: 1023px) {
  .trial__box {
    max-width: 100%;
    margin: 0 0 64px;
  }
}

@media only screen and (max-width: 767px) {
  .trial__box {
    margin-bottom: 48px;
  }
}

.trial__btn {
  flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
  .trial__btn {
    min-width: 100%;
  }
}

.switch {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0;
  transition: opacity .25s;
}

.switch__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch__in {
  display: inline-block;
}

.switch__box {
  position: relative;
  display: block;
  width: 56px;
  height: 32px;
  background: rgba(82, 203, 149, 0.08);
  border-radius: 16px;
  transition: all .25s;
}

.switch__box:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 6px solid #52cb95;
  transition: all .25s;
}

.switch__preview {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 0;
}

.switch__pic_moon {
  display: none;
}

.switch:hover {
  opacity: .85;
}

.switch__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch__category {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
}

.switch__category:not(:last-child) {
  margin-left: 24px;
}

.switch__yearly {
  display: none;
}

.switch__sale {
  display: none;
  margin-left: 16px;
  padding: 0 8px;
  border-radius: 12px;
  background: rgba(82, 203, 149, 0.08);
  line-height: 24px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: .02em;
  color: #52cb95;
}

.switch__input:checked + .switch__flex .switch__box:before {
  transform: translate(24px, -50%);
}

.switch__input:checked + .switch__flex .switch__yearly {
  display: block;
}

.switch__input:checked + .switch__flex .switch__monthly {
  display: none;
}

.switch__input:checked + .switch__flex .switch__sale {
  display: block;
}

.switch_img .switch__input:checked + .switch__in .switch__box:before {
  width: 6px;
  border-width: 3px;
  border-radius: 3px;
  transform: translate(31px, -50%);
}

.switch_img .switch__input:checked + .switch__in .switch__pic_moon {
  display: inline-block;
}

.switch_img .switch__input:checked + .switch__in .switch__pic_sun {
  display: none;
}

.switch_img .switch__in {
  padding-left: 56px;
}

.social {
  display: flex;
  align-items: center;
}

.social__link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid rgba(82, 203, 149, 0.24);
  transition: border-color .25s;
}

.social__link .icon {
  font-size: 20px;
  fill: #52cb95;
}

.social__link .icon-twitter {
  font-size: 18px;
}

.social__link .icon-facebook {
  font-size: 22px;
}

.social__link:hover {
  border-color: #52cb95;
}

.social__link:not(:last-child) {
  margin-right: 32px;
}

.footer {
  padding-bottom: 72px;
}

@media only screen and (max-width: 767px) {
  .footer {
    padding-bottom: 56px;
  }
}

.footer__center {
  max-width: 1236px;
}

.footer__row {
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .footer__row {
    flex-wrap: wrap;
  }
}

.footer__col:first-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 5px;
}

@media only screen and (max-width: 767px) {
  .footer__col:first-child {
    flex: 0 0 100%;
    margin-bottom: 48px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer__col:nth-child(2), .footer__col:nth-child(3) {
    padding-right: 24px;
  }
}

@media only screen and (max-width: 1023px) {
  .footer__col:nth-child(3) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .footer__col:last-child {
    flex: 0 0 100%;
    margin-top: 48px;
  }
}

.footer__col:not(:last-child) {
  margin-right: auto;
}

.footer__logo {
  margin-bottom: 60px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .footer__logo {
    margin-bottom: 48px;
  }
}

.footer__logo .footer__pic {
  width: 149px;
}

.footer__logo .footer__pic_white {
  display: none;
}

.footer__menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer__link {
  position: relative;
  font-weight: 700;
  letter-spacing: 0;
  color: #4C5D77;
  transition: color .25s;
}

.footer__link:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2px;
  width: 0;
  height: 1px;
  background: #52cb95;
  transition: width .25s;
}

.footer__link:hover {
  color: #52cb95;
}

.footer__link:hover:before {
  width: 100%;
}

.footer__link:not(:last-child) {
  margin-bottom: 24px;
}

.popup {
  position: relative;
  max-width: 504px;
  margin: 0 auto;
  background: #FAFBFF;
  border-radius: 32px;
}

@media only screen and (max-width: 767px) {
  .popup {
    max-width: 100%;
    min-height: 100vh;
    padding: 112px 32px 56px;
    border-radius: 0;
  }
}

.popup .mfp-close {
  position: absolute;
  top: 32px;
  right: 32px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid rgba(82, 203, 149, 0.24);
  line-height: normal;
  opacity: 1;
  font-size: 0;
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 14.2929C-0.0976311 14.6834 -0.0976311 15.3166 0.292893 15.7071C0.683418 16.0976 1.31658 16.0976 1.70711 15.7071L7.99999 9.41423L14.2929 15.7071C14.6834 16.0977 15.3166 16.0977 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L9.4142 8.00001L15.7071 1.70711C16.0976 1.31658 16.0976 0.683417 15.7071 0.292893C15.3166 -0.0976312 14.6834 -0.0976308 14.2929 0.292893L7.99999 6.5858L1.70711 0.292922C1.31658 -0.0976023 0.683419 -0.0976023 0.292895 0.292922C-0.0976295 0.683446 -0.0976295 1.31661 0.292895 1.70714L6.58577 8.00001L0.292893 14.2929Z' fill='%237737FF'/%3E%3C/svg%3E%0A") no-repeat 50% 50%/16px auto;
  transition: border-color .25s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media only screen and (max-width: 767px) {
  .popup .mfp-close {
    background-color: #52cb95;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 14.2929C-0.0976311 14.6834 -0.0976311 15.3166 0.292893 15.7071C0.683418 16.0976 1.31658 16.0976 1.70711 15.7071L7.99999 9.41423L14.2929 15.7071C14.6834 16.0977 15.3166 16.0977 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L9.4142 8.00001L15.7071 1.70711C16.0976 1.31658 16.0976 0.683417 15.7071 0.292893C15.3166 -0.0976312 14.6834 -0.0976308 14.2929 0.292893L7.99999 6.5858L1.70711 0.292922C1.31658 -0.0976023 0.683419 -0.0976023 0.292895 0.292922C-0.0976295 0.683446 -0.0976295 1.31661 0.292895 1.70714L6.58577 8.00001L0.292893 14.2929Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
  }
}

.popup .mfp-close:hover {
  border-color: #52cb95;
}

.popup_big {
  max-width: 1056px;
}

@media only screen and (max-width: 1199px) {
  .popup_big {
    max-width: 832px;
  }
}

.popup_big .mfp-close {
  top: 48px;
  right: 48px;
}

@media only screen and (max-width: 1023px) {
  .popup_big .mfp-close {
    top: 32px;
    right: 32px;
  }
}

.popup_login {
  display: flex;
  flex-direction: column;
}

.mfp-container {
  padding: 40px 30px 20px;
}

@media only screen and (max-width: 1199px) {
  .mfp-container {
    padding: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .mfp-container {
    padding: 0;
  }
}

@media (max-width: 900px) {
  .mfp-container {
    padding: 40px;
  }
}

.mfp-bg {
  background: rgba(20, 20, 27, 0.16);
}

.mfp-zoom-in .popup {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.2s ease-out;
}

.mfp-zoom-in.mfp-ready .popup {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 1;
}

.mfp-zoom-in.mfp-removing .popup {
  transform: scale(0.8);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.field__line {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

@media only screen and (max-width: 767px) {
  .field__line {
    margin-bottom: 8px;
  }
}

.field__label {
  letter-spacing: 0;
  color: #4C5D77;
}

@media only screen and (max-width: 767px) {
  .field__label {
    font-size: 14px;
    line-height: 1.71429;
  }
}

.field__line .field__label {
  margin-right: auto;
}

.field > .field__label {
  margin-bottom: 16px;
}

@media only screen and (max-width: 767px) {
  .field > .field__label {
    margin-bottom: 8px;
  }
}

.field__link {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  color: #52cb95;
}

@media only screen and (max-width: 767px) {
  .field__link {
    font-size: 14px;
    line-height: 1.71429;
  }
}

.field__link .icon {
  margin-left: 20px;
  font-size: 14px;
  fill: #52cb95;
  transition: transform .25s;
}

.field__link:hover .icon {
  transform: translateX(3px);
}

.field__wrap {
  position: relative;
}

.field__input {
  width: 100%;
  height: 64px;
  padding: 0 24px 0 58px;
  border-radius: 32px;
  border: 2px solid rgba(20, 20, 27, 0.08);
  background: none;
  font-family: 'Public Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -.01em;
  color: #00006D;
  transition: border-color .25s;
}

@media only screen and (max-width: 767px) {
  .field__input {
    height: 48px;
    padding: 0 16px 0 48px;
    border-radius: 24px;
    font-size: 16px;
  }
}

.field__input:focus {
  border-color: #52cb95;
}

.field__icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  padding-left: 3px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .field__icon {
    width: 48px;
  }
}

.field__icon .icon {
  font-size: 22px;
  fill: #4C5D77;
  transition: fill .25s;
}

@media only screen and (max-width: 767px) {
  .field__icon .icon {
    font-size: 18px;
  }
}

.field__icon .icon-email {
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .field__icon .icon-email {
    font-size: 15px;
  }
}

.field__input:focus + .field__icon .icon {
  fill: #52cb95;
}

.login {
  padding: 36px 32px;
}

@media only screen and (max-width: 767px) {
  .login {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .login__form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.login__title {
  margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .login__title {
    margin-bottom: 56px;
  }
}

.login__fieldset {
  margin-bottom: 56px;
}

@media only screen and (max-width: 767px) {
  .login__fieldset {
    margin-bottom: auto;
  }
}

.login__field:not(:last-child) {
  margin-bottom: 32px;
}

.login__btns {
  display: flex;
  margin: 0 -12px;
}

@media only screen and (max-width: 767px) {
  .login__btns {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 639px) {
  .login__btns {
    display: block;
    margin: 24px 0 0;
  }
}

.login__btn {
  flex: 0 0 calc(50% - 24px);
  min-width: calc(50% - 24px);
  margin: 0 12px;
  padding: 0 24px;
}

@media only screen and (max-width: 639px) {
  .login__btn {
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 639px) {
  .login__btn:not(:last-child) {
    margin-bottom: 16px;
  }
}

.pricing {
  padding: 96px 84px 38px;
}

@media only screen and (max-width: 1199px) {
  .pricing {
    padding: 48px;
  }
}

@media only screen and (max-width: 1023px) {
  .pricing {
    padding: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing {
    padding: 0;
  }
}

.pricing__head {
  margin-bottom: 48px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .pricing__head {
    margin-bottom: 40px;
  }
}

.pricing__title {
  margin-bottom: 16px;
}

.pricing__info {
  max-width: 480px;
  margin: 0 auto 48px;
  font-size: 20px;
  line-height: 1.6;
  color: #4C5D77;
}

@media only screen and (max-width: 767px) {
  .pricing__info {
    margin-bottom: 40px;
    line-height: 2;
  }
}

.pricing__list {
  display: flex;
  margin: 0 -24px;
}

@media only screen and (max-width: 1199px) {
  .pricing__list {
    align-items: flex-start;
    margin: 0 -16px;
  }
}

@media only screen and (max-width: 1023px) {
  .pricing__list {
    margin: 0 -12px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing__list {
    display: block;
    margin: 0;
  }
}

.pricing__item {
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(33.333% - 48px);
  width: calc(33.333% - 48px);
  margin: 0 24px;
  padding: 24px;
  border-radius: 24px;
  border: 2px solid rgba(20, 20, 27, 0.08);
  transition: border-color .25s;
}

@media only screen and (max-width: 1199px) {
  .pricing__item {
    display: block;
    flex: 0 0 calc(33.333% - 32px);
    width: calc(33.333% - 32px);
    margin: 0 16px;
  }
}

@media only screen and (max-width: 1023px) {
  .pricing__item {
    flex: 0 0 calc(33.333% - 24px);
    width: calc(33.333% - 24px);
    margin: 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing__item {
    width: 100%;
    margin: 0;
  }
}

.pricing__item_starter .pricing__money {
  color: #FF570F;
}

.pricing__item_starter .pricing__icon .icon {
  fill: #FF570F;
}

.pricing__item_pro .pricing__money {
  color: #3F9E2E;
}

.pricing__item_pro .pricing__icon .icon {
  fill: #3F9E2E;
}

.pricing__item_pro .pricing__link {
  border-bottom: 1px solid #FF570F;
  color: #FF570F;
}

.pricing__item_enterprise .pricing__money {
  color: #7737FF;
}

.pricing__item_enterprise .pricing__icon .icon {
  fill: #7737FF;
}

.pricing__item_enterprise .pricing__link {
  border-bottom: 1px solid #52cb95;
  color: #52cb95;
}

@media only screen and (max-width: 767px) {
  .pricing__item:not(:last-child) {
    margin-bottom: 24px;
  }
}

.pricing__item.pricing__item_starter:hover {
  border-color: #FF570F;
}

.pricing__item.pricing__item_pro:hover {
  border-color: #3F9E2E;
}

.pricing__item.pricing__item_enterprise:hover {
  border-color: #7737FF;
}

.pricing__name {
  margin-bottom: 8px;
  font-weight: 700;
  letter-spacing: 0;
}

.pricing__value {
  margin-bottom: 24px;
  font-size: 12px;
  letter-spacing: 0;
  color: #4C5D77;
}

.pricing__money {
  font-family: 'Merriweather', serif;
  font-size: 36px;
  line-height: 1.55556;
  font-weight: 900;
}

.pricing__money_monthly {
  display: none;
}

.pricing__money.active .pricing__money_monthly {
  display: inline;
}

.pricing__money.active .pricing__money_yearly {
  display: none;
}

.pricing__details {
  margin-bottom: auto;
}

.pricing__line {
  display: flex;
  font-size: 14px;
  line-height: 1.71429;
  letter-spacing: 0;
  color: #4C5D77;
}

@media only screen and (max-width: 1199px) {
  .pricing__line {
    font-size: 16px;
    line-height: 1.5;
  }
}

.pricing__line:not(:last-child) {
  margin-bottom: 24px;
}

.pricing__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  margin-right: 16px;
  font-size: 0;
}

.pricing__icon .icon-check {
  font-size: 22px;
}

.pricing__icon .icon-arrow-prev {
  font-size: 16px;
}

.pricing__link {
  font-weight: 700;
  transition: border-color .25s;
}

.pricing__link:hover {
  border-color: transparent;
}

.pricing__btn {
  margin-top: 32px;
}

@media only screen and (max-width: 1023px) {
  .pricing__btn {
    padding: 0 16px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing__btn {
    min-width: 100%;
    margin-top: 40px;
  }
}

.accord {
  padding: 96px 48px 0;
}

@media only screen and (max-width: 1199px) {
  .accord {
    padding: 48px 48px 0;
  }
}

@media only screen and (max-width: 1023px) {
  .accord {
    padding: 32px 32px 0;
  }
}

@media only screen and (max-width: 767px) {
  .accord {
    padding: 0;
  }
}

.accord > .accord__title {
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .accord > .accord__title {
    padding: 0 85px;
  }
}

@media only screen and (max-width: 767px) {
  .accord > .accord__title {
    padding: 0;
  }
}

.accord__info {
  max-width: 480px;
  margin: 0 auto 48px;
  text-align: center;
  font-size: 20px;
  line-height: 1.6;
  color: #4C5D77;
}

@media only screen and (max-width: 767px) {
  .accord__info {
    margin-bottom: 40px;
    line-height: 2;
  }
}

.accord__list {
  padding-bottom: 32px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 1023px) {
  .accord__list {
    padding: 0;
  }
}

.accord__list::-webkit-scrollbar {
  display: none;
}

.accord__section:not(:last-child) {
  margin-bottom: 48px;
}

@media only screen and (max-width: 767px) {
  .accord__section:not(:last-child) {
    margin-bottom: 40px;
  }
}

.accord__section .accord__title {
  margin-bottom: 16px;
}

@media only screen and (max-width: 767px) {
  .accord__section .accord__title {
    font-size: 20px;
    line-height: 1.6;
  }
}

.accord__item {
  padding: 8px 0;
  border-top: 1px solid rgba(20, 20, 27, 0.08);
}

.accord__item:last-child {
  border-bottom: 1px solid rgba(20, 20, 27, 0.08);
}

.accord__head {
  position: relative;
  padding: 16px 40px 16px 0;
  font-size: 20px;
  line-height: 1.6;
  font-weight: 600;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: opacity .25s;
}

.accord__head:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 14px;
  height: 8px;
  background: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z' fill='%237737FF'/%3E%3C/svg%3E%0A") no-repeat 50% 50%/auto 100%;
  transition: transform .25s;
}

.accord__head:hover {
  opacity: .8;
}

.accord__item.active .accord__head:before {
  transform: translateY(-50%) rotate(180deg);
}

.accord__body {
  display: none;
  padding-bottom: 16px;
  padding-right: 120px;
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0;
  color: #4C5D77;
}

@media only screen and (max-width: 1199px) {
  .accord__body {
    padding-right: 0;
  }
}

.accord_faq .accord__title {
  margin-bottom: 48px;
}

@media only screen and (max-width: 767px) {
  .accord_faq .accord__title {
    margin-bottom: 40px;
  }
}

.accord_faq .accord__list {
  max-height: 535px;
}

@media only screen and (max-width: 767px) {
  .accord_faq .accord__list {
    max-height: 100%;
  }
}

.accord_work .accord__title {
  margin-bottom: 16px;
}

.accord_work .accord__list {
  max-height: 485px;
}

@media only screen and (max-width: 767px) {
  .accord_work .accord__list {
    max-height: 100%;
  }
}

body.dark .header__pic_white {
  display: inline-block;
}

body.dark .header__pic_dark {
  display: none;
}

@media only screen and (max-width: 1023px) {
  body.dark .header__wrap {
    background: #14141B;
  }
}

body.dark .header__link {
  color: #9BAABF;
}

@media only screen and (max-width: 1023px) {
  body.dark .header__link {
    color: #F7FAFC;
  }
}

body.dark .header__link:hover {
  color: #52cb95;
}

body.dark .header__login .header__text {
  color: #9BAABF;
}

body.dark .header__login:hover .header__text {
  color: #52cb95;
}

body.dark .main__title[data-aos] {
  color: #9BAABF;
}

body.dark .main__title[data-aos].aos-animate {
  color: #F7FAFC;
}

body.dark .info {
  color: #9BAABF;
}

body.dark .btn_border {
  border-color: #1E1E27;
  color: #F7FAFC;
}

body.dark .btn_border:hover {
  border-color: #F7FAFC;
}

body.dark .quality__item .quality__icon {
  background: #1E1E27;
}

body.dark .details__link {
  color: #F7FAFC;
}

body.dark .review__info {
  color: #9BAABF;
}

body.dark .review .owl-carousel button.owl-dot {
  border-color: #14141B;
  background: #fff;
  transition: all .25s;
}

body.dark .review .owl-carousel button.owl-dot.active {
  border-color: #fff;
  background: #14141B;
}

body.dark .indicators__item .indicators__icon {
  background: #1E1E27;
}

body.dark .trial__wrap {
  background-color: #1E1E27;
  background-image: url("../img/pattern-light.png");
}

@media only screen and (max-width: 1023px) {
  body.dark .trial__wrap {
    background: none;
  }
}

body.dark .switch__box {
  background: #1E1E27;
}

body.dark .switch__box:before {
  border-color: #fff;
}

body.dark .switch__sale {
  background: #1E1E27;
  color: #F7FAFC;
}

body.dark .social__link {
  border-color: #1E1E27;
}

body.dark .social__link .icon {
  fill: #FAFBFF;
}

body.dark .social__link:hover {
  border-color: #F7FAFC;
}

body.dark .footer__pic_white {
  display: inline-block;
}

body.dark .footer__pic_dark {
  display: none;
}

body.dark .footer__link {
  color: #9BAABF;
}

body.dark .footer__link:hover {
  color: #52cb95;
}

body.dark .popup {
  background: #14141B;
}

body.dark .popup .mfp-close {
  border-color: #1E1E27;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 14.2929C-0.0976311 14.6834 -0.0976311 15.3166 0.292893 15.7071C0.683418 16.0976 1.31658 16.0976 1.70711 15.7071L7.99999 9.41423L14.2929 15.7071C14.6834 16.0977 15.3166 16.0977 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L9.4142 8.00001L15.7071 1.70711C16.0976 1.31658 16.0976 0.683417 15.7071 0.292893C15.3166 -0.0976312 14.6834 -0.0976308 14.2929 0.292893L7.99999 6.5858L1.70711 0.292922C1.31658 -0.0976023 0.683419 -0.0976023 0.292895 0.292922C-0.0976295 0.683446 -0.0976295 1.31661 0.292895 1.70714L6.58577 8.00001L0.292893 14.2929Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
}

body.dark .mfp-bg {
  background: rgba(30, 30, 39, 0.88);
}

body.dark .field__label {
  color: #9BAABF;
}

body.dark .field__input {
  border-color: #1E1E27;
  color: #F7FAFC;
}

body.dark .field__input:focus {
  border-color: #fff;
}

body.dark .field__icon .icon {
  fill: #9BAABF;
}

body.dark .field__input:focus + .field__icon .icon {
  fill: #fff;
}

body.dark .field__link {
  color: #F7FAFC;
}

body.dark .pricing__info {
  color: #9BAABF;
}

body.dark .pricing__item {
  border-color: #1E1E27;
}

body.dark .pricing__line {
  color: #9BAABF;
}

body.dark .accord__info {
  color: #9BAABF;
}

body.dark .accord__head:before {
  background: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
}

body.dark .accord__body {
  color: #9BAABF;
}

[data-aos="animation-scale-y"] {
  transform: translateY(40px) scaleY(1.3);
  opacity: 0;
  transition-property: transform, opacity;
}

[data-aos="animation-scale-y"].aos-animate {
  transform: translateY(0) scaleY(1);
  opacity: 1;
}

[data-aos="animation-scale-y-and-color"] {
  transform: translateY(40px) scaleY(1.3);
  opacity: 0;
  color: #52cb95;
  transition-property: transform, opacity, color;
}

[data-aos="animation-scale-y-and-color"].aos-animate {
  transform: translateY(0) scaleY(1);
  opacity: 1;
  color: #00006D;
}
