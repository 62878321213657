@mixin w {
  @media only screen and (max-width: "1599px") {
    @content; } }
@mixin x {
  @media only screen and (max-width: "1365px") {
    @content; } }
@mixin d {
  @media only screen and (max-width: "1199px") {
    @content; } }
@mixin t {
  @media only screen and (max-width: "1023px") {
    @content; } }
@mixin m {
  @media only screen and (max-width: "767px") {
    @content; } }
@mixin a {
  @media only screen and (max-width: "639px") {
    @content; } }
@mixin s {
  @media only screen and (max-width: "474px") {
    @content; } }

@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@900&family=Public+Sans:wght@400;500;600;700&display=swap');
@mixin f {
  font-family: 'Public Sans', sans-serif; }
@mixin fm {
  font-family: 'Merriweather', serif; }

$wh: #fff;
$purple: #7737FF;
$orange: #FF9900;
$pink: #FB5392;
$blue: #20DAF1;
//$green: #69C85A
$green: #52cb95;
$green-dark: #3F9E2E;
$red: #FF570F;
$bg: #FAFBFF;
$bg-dark: #F3F6FF;
$text: #4C5D77;

// dark theme
$bl: #14141B;
$bl-light: #1E1E27;
$dark: #00006D;
$gray: #9BAABF;
$wh-dark: #F7FAFC;

html {
  // height: 100%
  &.no-scroll {
    overflow: hidden; } }

body {
  min-width: 375px;
  background: $bg;
  @include f;
  font-size: 16px;
  letter-spacing: -.015em;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark;
  &.dark {
    background: $bl;
    color: $wh-dark; }
  &.no-scroll {
    overflow: hidden; } }
video {
  width: 100%;
  max-height: 700px; }
button,
input,
textarea,
select {
  @include f; }

.page {
  overflow: hidden; }

.center {
  max-width: 1332px;
  margin: 0 auto;
  padding: 0 42px;
  @include t {
    padding: 0 40px; }
  @include m {
    padding: 0 32px; } }

a.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center; }

.btn {
  min-width: 154px;
  height: 64px;
  padding: 0 32px;
  border-radius: 32px;
  @include f;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -.01em;
  transition: all .25s;
  @include m {
    height: 56px;
    border-radius: 28px;
    font-size: 16px; }
  .icon,
  &__text {
    display: inline-block;
    vertical-align: middle; }
  .icon-arrow-right {
    margin: 0 30px 0 6px;
    font-size: 21px;
    @include m {
      margin: 0 20px 0 0;
      font-size: 16px; } }
  &_purple {
    background: $purple;
    color: #F7FAFC;
    .icon {
      fill: $wh; }
    &:hover {
      background: #6E34EA; } }
  &_border {
    padding: 0 30px;
    border: 2px solid rgba($purple, .24);
    color: $purple;
    &:hover {
      border-color: $purple; } }
  &_red {
    background: $red;
    color: #F7FAFC;
    &:hover {
      background: #FF5207; } }
  &_green {
    background: $green;
    color: #F7FAFC;
    &:hover {
      background: #459836; } }
  &_sm {
    height: 48px;
    border-radius: 24px;
    font-size: 16px;
    @include m {
      height: 48px;
      border-radius: 24px; } } }

.header {
  position: relative;
  z-index: 10;
  margin-bottom: 24px;
  padding: 48px 0;
  letter-spacing: 0;
  @include t {
    margin-bottom: 40px;
    padding: 32px 0; }
  @include m {
    margin-bottom: 24px; }
  &__center {
    display: flex;
    align-items: center;
    @include d {
      padding: 0 48px; }
    @include t {
      padding: 0 40px; } }
  &__logo {
    font-size: 0;
    @include t {
      position: relative;
      z-index: 15;
      margin-right: auto; } }
  &__logo &__pic {
    width: 100px;
    &_white {
      display: none; } }
  &__wrap {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 96px;
    @include t {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      min-height: 100vh;
      padding: 152px 40px 72px;
      background: $bg;
      transform: translateX(100%);
      transition: transform .3s;
      &.visible {
        transform: translateX(0); } }
    @include m {
      padding: 110px 32px 56px; }
    @include s {
      padding-top: 136px; } }
  &__nav {
    display: flex;
    margin-right: auto;
    @include t {
      flex-direction: column;
      margin: 0 0 auto; }
    @include m {
      flex-direction: row; }
    @include s {
      flex-direction: column; } }
  &__link {
    position: relative;
    font-weight: 700;
    color: $text;
    transition: color .25s;
    @include t {
      padding-right: 40px;
      @include fm;
      font-size: 36px;
      line-height: (56/36);
      font-weight: 900;
      color: $dark;
      &:after {
        content: "";
        position: absolute;
        top: 16px;
        right: 6px;
        width: 12px;
        height: 21px;
        background: url("data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C-0.0976315 0.683417 -0.0976315 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976315 12.6834 -0.0976315 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7.70711 7.70711C8.09763 7.31658 8.09763 6.68342 7.70711 6.29289L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893Z' fill='%237737FF'/%3E%3C/svg%3E%0A") no-repeat 50% 50% / 100% auto; } }
    @include m {
      font-size: 24px;
      line-height: (40/24);
      &:after {
        top: 13px;
        width: 8px;
        height: 14px; } }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 2px;
      width: 0;
      height: 1px;
      background: $green;
      transition: width .25s;
      @include t {
        display: none; } }
    &:hover {
      color: $green;
      &:before {
        width: 100%; } }
    @include nl {
      margin-right: 48px;
      @include t {
        margin: 0 0 48px; }
      @include m {
        margin: 0 32px 0 0; }
      @include s {
        margin: 0 0 48px; } } }
  &__control {
    display: flex;
    align-items: center;
    @include t {
      flex-direction: column-reverse;
      margin-top: 24px; } }
  &__login {
    display: inline-flex;
    align-items: center;
    margin-right: 36px;
    @include t {
      margin: 24px 0 0; }
    .icon {
      margin-left: 20px;
      font-size: 14px;
      fill: $green; } }
  &__login &__text {
    position: relative;
    font-weight: 700;
    color: $text;
    transition: color .25s;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 2px;
      width: 0;
      height: 1px;
      background: $green;
      transition: width .25s; } }
  &__login:hover &__text {
    color: $green;
    &:before {
      width: 100%; } }
  &__btn {
    height: 56px;
    border-radius: 28px;
    font-size: 16px;
    @include t {
      min-width: 100%; } }
  &__burger {
    display: none;
    position: relative;
    z-index: 12;
    width: 48px;
    height: 48px;
    padding: 10px;
    background: $green;
    border-radius: 50%;
    font-size: 0;
    transition: opacity .25s;
    @include t {
      display: inline-block; }
    &:before,
    &:after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 2px;
      background: $wh;
      border-radius: 2px;
      transition: transform .25s; }
    &:before {
      margin-bottom: 3px; }
    &:after {
      margin-top: 3px; }
    &.active {
      &:before {
        transform: translateY(4px) rotate(45deg); }
      &:after {
        transform: translateY(-4px) rotate(-45deg); } } } }

.section {
  @include nl {
    margin-bottom: 144px;
    @include x {
      margin-bottom: 120px; }
    @include m {
      margin-bottom: 104px; } } }

.title {
  margin-bottom: 32px;
  @include fm;
  font-size: 36px;
  line-height: (56/36);
  font-weight: 900;
  letter-spacing: -.01em;
  @include m {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: (40/24); }
  &__link {
    border-bottom: 3px solid $green;
    color: $green;
    transition: border-color .25s;
    @include m {
      border-width: 2px; }
    &:hover {
      border-color: transparent; } }
  &_sm {
    font-size: 24px;
    line-height: (40/24); }
  &_big {
    font-size: 48px;
    line-height: (72/48);
    @include m {
      font-size: 36px;
      line-height: (56/36); } } }

.info {
  font-size: 24px;
  line-height: (40/24);
  color: $text;
  @include m {
    font-size: 20px;
    line-height: 2; } }

.main {
  position: relative;
  padding: 93px 0;
  @include x {
    padding: 80px 0; }
  @include d {
    margin-bottom: 120px;
    padding: 0;
    text-align: center; }
  @include m {
    margin-bottom: 104px; }
  &__wrap {
    max-width: 576px;
    @include d {
      max-width: 704px;
      margin: 0 auto 72px; }
    @include m {
      margin-bottom: 48px; } }
  &__info {
    margin-bottom: 64px;
    font-size: 24px;
    line-height: (40/24);
    @include m {
      margin-bottom: 48px; } }
  &__btns {
    display: flex;
    @include d {
      justify-content: center; }
    @include a {
      display: block; } }
  &__btn {
    @include a {
      min-width: 100%; }
    @include nl {
      margin-right: 32px;
      @include a {
        margin: 0 0 24px; } } }
  &__preview {
    position: absolute;
    top: 0;
    right: calc(50% - 900px);
    pointer-events: none;
    font-size: 0;
    opacity: 0;
    transform: translateX(100px);
    animation: image 1s forwards;
    @include x {
       right: calc(50% - 800px); }
    @include d {
      position: static; } }
  &__pic {
    @include x {
      width: 770px; }
    @include d {
      width: 848px; }
    @include t {
      width: 100%;
      max-width: 768px; } }
  &__video {
    @include x {
      max-height: 700px; }
    @include d {
      max-height: 700px; }
    @include t {
      max-height: 700px; } } }


@keyframes image {
  100% {
    transform: translateX(0);
    opacity: 1; } }

.quality {
  &__center {
    max-width: 960px; }
  &__head {
    margin-bottom: 80px;
    text-align: center;
    @include t {
      margin-bottom: 72px; }
    @include m {
      margin-bottom: 56px; } }
  &__list {
    display: flex;
    @include t {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: -48px; }
    @include m {
      display: block;
      max-width: 240px;
      margin: 0 auto; } }
  &__item {
    display: flex;
    align-items: center;
    letter-spacing: 0;
    font-weight: 700;
    @include t {
      justify-content: center;
      flex: 0 0 50%;
      width: 50%;
      margin-top: 48px; }
    @include m {
      justify-content: flex-start;
      width: 100%;
      margin: 0; }
    &:first-child {
      .quality__icon {
        background: rgba($blue, .08); } }
    &:nth-child(2) {
      .quality__icon {
        background: rgba($orange, .08); } }
    &:nth-child(3) {
      .quality__icon {
        background: rgba($red, .08); } }
    @include nl {
      margin-right: auto;
      @include t {
        margin-right: 0; }
      @include m {
        margin: 0 0 48px; } } }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    margin-right: 24px;
    border-radius: 50%;
    font-size: 0; }
  &__pic {
    max-width: 100%;
    max-height: 100%; } }

.details {
  &__center {
    max-width: 1236px; }
  &__item {
    display: flex;
    align-items: center;
    &:first-child {

      .details__pic {
        @include m {
          width: 500px; } } }
    &:nth-child(2) {

      .details__pic {
        @include m {
          width: 630px; } } }
    &:nth-child(3) {


      .details__pic {
        @include m {
          width: 630px; } } }
    &:nth-child(2n+1) {
      @include d {
        flex-direction: column; }
      .details__preview {
        margin-right: auto;
        @include d {
          margin: 0 auto 72px; }
        @include m {
          margin-bottom: 56px; } }
      .details__box {
        &[data-aos] {
          transform: translateX(100px) skewX(-20deg);
          opacity: 0;
          transition-property: transform, opacity;
          &.aos-animate {
            transform: translateX(0) skewX(0deg);
            opacity: 1; } } } }
    &:nth-child(2n) {
      @include d {
        flex-direction: column-reverse; }
      .details__preview {
        margin-left: auto;
        @include d {
          margin: 0 auto 72px; }
        @include m {
          margin-bottom: 56px; } }
      .details__box {
        &[data-aos] {
          transform: translateX(-100px) skewX(20deg);
          opacity: 0;
          transition-property: transform, opacity;
          &.aos-animate {
            transform: translateX(0) skewX(0deg);
            opacity: 1; } } } }
    @include nl {
      margin-bottom: 144px;
      @include x {
        margin-bottom: 120px; }
      @include m {
        margin-bottom: 56px; } } }
  &__preview {
    position: relative;
    flex-shrink: 0;
    width: 528px;
    height: 528px;
    border-radius: 50%;
    overflow: hidden;
    font-size: 0;
    @include m {
      width: 310px;
      height: 310px; } }
  &__icon {
    position: absolute; }
  &__box {
    max-width: 480px;
    @include d {
      max-width: 528px;
      text-align: center; }
    @include t {
      max-width: 100%;
      text-align: left; } }
  &__info {
    margin-bottom: 48px;
    @include m {
      margin-bottom: 40px; } }
  &__link {
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    color: $green;
    .icon {
      margin-left: 20px;
      font-size: 14px;
      fill: $green;
      transition: transform .25s; }
    &:hover .icon {
      transform: translateX(3px); } } }

.system {
  &__head {
    max-width: 710px;
    margin: 0 auto 10px;
    text-align: center;
    @include x {
      margin-bottom: 72px; }
    @include m {
      margin-bottom: 56px; } }
  &__preview {
    height: 700px;
    margin-right: -110px;
    text-align: center;
    font-size: 0;
    @include x {
      height: 660px; }
    @include t {
      height: 407px;
      margin-right: -60px; }
    @include m {
      height: 238px;
      margin-right: -10px; }
    &[data-aos] {
      transform: translateY(100px);
      opacity: 0;
      transition-property: transform, opacity;
      &.aos-animate {
        transform: translateY(0);
        opacity: 1; } } }
  &__pic {
    max-width: 100%;
    @include x {
      max-width: 940px; }
    @include t {
      max-width: 576px; }
    @include m {
      max-width: 340px; } } }

.review {
  &__center {
    display: flex;
    @include d {
      display: block;
      text-align: center; } }
  &__title {
    max-width: 488px;
    margin: 0 auto 0 0;
    @include d {
      max-width: 704px;
      margin: 0 auto 72px; }
    @include m {
      margin-bottom: 56px; } }
  &__container {
    flex-shrink: 0;
    width: 616px;
    @include d {
      width: 488px;
      margin: 0 auto; }
    @include m {
      width: 100%; } }
  &__item {
    display: flex;
    @include d {
      display: block; } }
  &__ava {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-right: 48px;
    border-radius: 50%;
    overflow: hidden;
    font-size: 0;
    @include d {
      margin: 0 auto 48px; }
    @include m {
      width: 64px;
      height: 64px;
      margin-bottom: 24px; } }
  &__pic {
    min-height: 100%; }
  &__details {
    flex-grow: 1; }
  &__blockquote {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: (40/24);
    font-weight: 700;
    @include m {
      font-size: 20px;
      line-height: 1.6; } }
  &__info {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 2;
    color: $text; }
  &__author {
    font-weight: 500;
    color: $pink; }
  .owl-dots {
    display: flex;
    margin-top: 48px;
    padding-left: 127px;
    @include d {
      justify-content: center;
      padding: 0; }
    @include m {
      margin-top: 40px; } }
  .owl-carousel button.owl-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid $bg;
    background: $green;
    transition: all .25s;
    &:hover {
      opacity: .7; }
    &.active {
      border-color: $green;
      background: transparent;
      opacity: 1; }
    @include nl {
      margin-right: 15px; } } }

.indicators {
  &__center {
    max-width: 856px; }
  &__head {
    max-width: 704px;
    margin: 0 auto 144px;
    text-align: center;
    @include x {
      margin-bottom: 120px; }
    @include m {
      margin-bottom: 104px; } }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -80px;
    @include t {
      display: block;
      text-align: center;
      margin-top: 0; } }
  &__item {
    min-width: 308px;
    margin-top: 80px;
    @include t {
      max-width: 100%;
      margin-top: 0; }
    &:nth-child(2n+1) {
      margin-right: auto; }
    &:first-child {
      .indicators__icon {
        background: rgba($green, .08); }
      .indicators__counter {
        color: $green; } }
    &:nth-child(2) {
      .indicators__icon {
        background: rgba($pink, .08); }
      .indicators__counter {
        color: $pink; } }
    &:nth-child(3) {
      .indicators__icon {
        background: rgba($orange, .08); }
      .indicators__counter {
        color: $orange; } }
    &:nth-child(4) {
      .indicators__icon {
        background: rgba($green, .08); }
      .indicators__counter {
        color: $green; } }
    @include nl {
      @include t {
        margin-bottom: 72px; }
      @include m {
        margin-bottom: 56px; } } }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    margin-bottom: 32px;
    border-radius: 50%;
    font-size: 0;
    @include t {
      margin: 0 auto 32px; } }
  &__pic {
    max-width: 100%;
    max-height: 100%; }
  &__counter {
    margin-bottom: 24px;
    @include fm;
    font-size: 48px;
    line-height: (72/48);
    font-weight: 900;
    @include m {
      margin-bottom: 16px;
      font-size: 36px;
      line-height: (56/36); } } }

.trial {
  &__wrap {
    display: flex;
    align-items: center;
    padding: 104px 96px;
    border-radius: 56px;
    background: $bg-dark url('../img/pattern.png') no-repeat 50% 50% / cover;
    @include x {
      padding: 72px 48px; }
    @include t {
      display: block;
      padding: 0;
      background: none;
      text-align: center; } }
  &__box {
    max-width: 670px;
    margin-right: auto;
    @include d {
      max-width: 490px; }
    @include t {
      max-width: 100%;
      margin: 0 0 64px; }
    @include m {
      margin-bottom: 48px; } }
  &__btn {
    flex-shrink: 0;
    @include m {
      min-width: 100%; } } }

.switch {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  font-size: 0;
  transition: opacity .25s;
  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
  &__in {
    display: inline-block; }
  &__box {
    position: relative;
    display: block;
    width: 56px;
    height: 32px;
    background: rgba($green, .08);
    border-radius: 16px;
    transition: all .25s;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 6px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 6px solid $green;
      transition: all .25s; } }
  &__preview {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 0; }
  &__pic_moon {
    display: none; }
  &:hover {
    opacity: .85; }
  &__flex {
    display: flex;
    justify-content: center;
    align-items: center; }
  &__category {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    @include nl {
      margin-left: 24px; } }
  &__yearly {
    display: none; }
  &__sale {
    display: none;
    margin-left: 16px;
    padding: 0 8px;
    border-radius: 12px;
    background: rgba($green, .08);
    line-height: 24px;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: .02em;
    color: $green; }
  &__input:checked + &__flex &__box:before {
    transform: translate(24px,-50%); }
  &__input:checked + &__flex &__yearly {
    display: block; }
  &__input:checked + &__flex &__monthly {
    display: none; }
  &__input:checked + &__flex &__sale {
    display: block; }
  &_img &__input:checked + &__in &__box:before {
    width: 6px;
    border-width: 3px;
    border-radius: 3px;
    transform: translate(31px,-50%); }
  &_img &__input:checked + &__in &__pic_moon {
    display: inline-block; }
  &_img &__input:checked + &__in &__pic_sun {
    display: none; }
  &_img &__in {
    padding-left: 56px; } }

.social {
  display: flex;
  align-items: center;
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid rgba($green, .24);
    transition: border-color .25s;
    .icon {
      font-size: 20px;
      fill: $green; }
    .icon-twitter {
      font-size: 18px; }
    .icon-facebook {
      font-size: 22px; }
    &:hover {
      border-color: $green; }
    @include nl {
      margin-right: 32px; } } }

.footer {
  padding-bottom: 72px;
  @include m {
    padding-bottom: 56px; }
  &__center {
    max-width: 1236px; }
  &__row {
    display: flex;
    @include t {
      flex-wrap: wrap; } }
  &__col {
    &:first-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 5px;
      @include m {
        flex: 0 0 100%;
        margin-bottom: 48px;
        padding: 0; } }
    &:nth-child(2),
    &:nth-child(3) {
      @include m {
        padding-right: 24px; } }
    &:nth-child(3) {
      @include t {
        margin-right: 0; } }
    &:last-child {
      @include t {
        flex: 0 0 100%;
        margin-top: 48px; } }
    @include nl {
      margin-right: auto; } }
  &__logo {
    margin-bottom: 60px;
    font-size: 0;
    @include m {
      margin-bottom: 48px; } }
  &__logo &__pic {
    width: 149px;
    &_white {
      display: none; } }
  &__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  &__link {
    position: relative;
    font-weight: 700;
    letter-spacing: 0;
    color: $text;
    transition: color .25s;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 2px;
      width: 0;
      height: 1px;
      background: $green;
      transition: width .25s; }
    &:hover {
      color: $green;
      &:before {
        width: 100%; } }
    @include nl {
      margin-bottom: 24px; } } }

.popup {
  position: relative;
  max-width: 504px;
  margin: 0 auto;
  background: $bg;
  border-radius: 32px;
  @include m {
    max-width: 100%;
    min-height: 100vh;
    padding: 112px 32px 56px;
    border-radius: 0; }
  .mfp-close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid rgba($green, .24);
    line-height: normal;
    opacity: 1;
    font-size: 0;
    background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 14.2929C-0.0976311 14.6834 -0.0976311 15.3166 0.292893 15.7071C0.683418 16.0976 1.31658 16.0976 1.70711 15.7071L7.99999 9.41423L14.2929 15.7071C14.6834 16.0977 15.3166 16.0977 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L9.4142 8.00001L15.7071 1.70711C16.0976 1.31658 16.0976 0.683417 15.7071 0.292893C15.3166 -0.0976312 14.6834 -0.0976308 14.2929 0.292893L7.99999 6.5858L1.70711 0.292922C1.31658 -0.0976023 0.683419 -0.0976023 0.292895 0.292922C-0.0976295 0.683446 -0.0976295 1.31661 0.292895 1.70714L6.58577 8.00001L0.292893 14.2929Z' fill='%237737FF'/%3E%3C/svg%3E%0A") no-repeat 50% 50% / 16px auto;
    transition: border-color .25s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include m {
      background-color: $green;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 14.2929C-0.0976311 14.6834 -0.0976311 15.3166 0.292893 15.7071C0.683418 16.0976 1.31658 16.0976 1.70711 15.7071L7.99999 9.41423L14.2929 15.7071C14.6834 16.0977 15.3166 16.0977 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L9.4142 8.00001L15.7071 1.70711C16.0976 1.31658 16.0976 0.683417 15.7071 0.292893C15.3166 -0.0976312 14.6834 -0.0976308 14.2929 0.292893L7.99999 6.5858L1.70711 0.292922C1.31658 -0.0976023 0.683419 -0.0976023 0.292895 0.292922C-0.0976295 0.683446 -0.0976295 1.31661 0.292895 1.70714L6.58577 8.00001L0.292893 14.2929Z' fill='%23ffffff'/%3E%3C/svg%3E%0A"); }
    &:hover {
      border-color: $green; } }
  &_big {
    max-width: 1056px;
    @include d {
      max-width: 832px; }
    .mfp-close {
      top: 48px;
      right: 48px;
      @include t {
        top: 32px;
        right: 32px; } } }
  &_login {
    display: flex;
    flex-direction: column; } }

.mfp-container {
  padding: 40px 30px 20px;
  @include d {
    padding: 40px; }
  @include m {
    padding: 0; } }

@media (max-width: 900px) {
  .mfp-container {
    padding: 40px; } }

.mfp-bg {
  background: rgba($bl, .16); }

.mfp-zoom-in {
  .popup {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8); }
  &.mfp-bg {
    opacity: 0;
    transition: all 0.2s ease-out; }
  &.mfp-ready {
    .popup {
      opacity: 1;
      transform: scale(1); }
    &.mfp-bg {
      opacity: 1; } }
  &.mfp-removing {
    .popup {
      transform: scale(0.8);
      opacity: 0; }
    &.mfp-bg {
      opacity: 0; } } }

.field {
  &__line {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    @include m {
      margin-bottom: 8px; } }
  &__label {
    letter-spacing: 0;
    color: $text;
    @include m {
      font-size: 14px;
      line-height: (24/14); } }
  &__line &__label {
    margin-right: auto; }
  & > &__label {
    margin-bottom: 16px;
    @include m {
      margin-bottom: 8px; } }
  &__link {
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    color: $green;
    @include m {
      font-size: 14px;
      line-height: (24/14); }
    .icon {
      margin-left: 20px;
      font-size: 14px;
      fill: $green;
      transition: transform .25s; }
    &:hover .icon {
      transform: translateX(3px); } }
  &__wrap {
    position: relative; }
  &__input {
    width: 100%;
    height: 64px;
    padding: 0 24px 0 58px;
    border-radius: 32px;
    border: 2px solid rgba($bl, .08);
    background: none;
    @include f;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -.01em;
    color: $dark;
    transition: border-color .25s;
    @include m {
      height: 48px;
      padding: 0 16px 0 48px;
      border-radius: 24px;
      font-size: 16px; }
    &:focus {
      border-color: $green; } }
  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    padding-left: 3px;
    font-size: 0;
    @include m {
      width: 48px; }
    .icon {
      font-size: 22px;
      fill: $text;
      transition: fill .25s;
      @include m {
        font-size: 18px; } }
    .icon-email {
      font-size: 18px;
      @include m {
        font-size: 15px; } } }
  &__input:focus + &__icon .icon {
    fill: $green; } }

.login {
  padding: 36px 32px;
  @include m {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0; }
  &__form {
    @include m {
      display: flex;
      flex-direction: column;
      flex-grow: 1; } }
  &__title {
    margin-bottom: 60px;
    @include m {
      margin-bottom: 56px; } }
  &__fieldset {
    margin-bottom: 56px;
    @include m {
      margin-bottom: auto; } }
  &__field {
    @include nl {
      margin-bottom: 32px; } }
  &__btns {
    display: flex;
    margin: 0 -12px;
    @include m {
      margin-top: 24px; }
    @include a {
      display: block;
      margin: 24px 0 0; } }
  &__btn {
    flex: 0 0 calc(50% - 24px);
    min-width: calc(50% - 24px);
    margin: 0 12px;
    padding: 0 24px;
    @include a {
      width: 100%;
      margin: 0; }
    @include nl {
      @include a {
        margin-bottom: 16px; } } } }

.pricing {
  padding: 96px 84px 38px;
  @include d {
    padding: 48px; }
  @include t {
    padding: 32px; }
  @include m {
    padding: 0; }
  &__head {
    margin-bottom: 48px;
    text-align: center;
    @include m {
      margin-bottom: 40px; } }
  &__title {
    margin-bottom: 16px; }
  &__info {
    max-width: 480px;
    margin: 0 auto 48px;
    font-size: 20px;
    line-height: 1.6;
    color: $text;
    @include m {
      margin-bottom: 40px;
      line-height: 2; } }
  &__list {
    display: flex;
    margin: 0 -24px;
    @include d {
      align-items: flex-start;
      margin: 0 -16px; }
    @include t {
      margin: 0 -12px; }
    @include m {
      display: block;
      margin: 0; } }
  &__item {
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(33.333% - 48px);
    width: calc(33.333% - 48px);
    margin: 0 24px;
    padding: 24px;
    border-radius: 24px;
    border: 2px solid rgba($bl, .08);
    transition: border-color .25s;
    @include d {
      display: block;
      flex: 0 0 calc(33.333% - 32px);
      width: calc(33.333% - 32px);
      margin: 0 16px; }
    @include t {
      flex: 0 0 calc(33.333% - 24px);
      width: calc(33.333% - 24px);
      margin: 0 12px; }
    @include m {
      width: 100%;
      margin: 0; }
    &_starter {
      .pricing__money {
        color: $red; }
      .pricing__icon .icon {
        fill: $red; } }
    &_pro {
      .pricing__money {
        color: $green-dark; }
      .pricing__icon .icon {
        fill: $green-dark; }
      .pricing__link {
        border-bottom: 1px solid $red;
        color: $red; } }
    &_enterprise {
      .pricing__money {
        color: $purple; }
      .pricing__icon .icon {
        fill: $purple; }
      .pricing__link {
        border-bottom: 1px solid $green;
        color: $green; } }
    @include nl {
      @include m {
        margin-bottom: 24px; } } }
  &__item.pricing__item_starter {
    &:hover {
      border-color: $red; } }
  &__item.pricing__item_pro {
    &:hover {
      border-color: $green-dark; } }
  &__item.pricing__item_enterprise {
    &:hover {
      border-color: $purple; } }
  &__name {
    margin-bottom: 8px;
    font-weight: 700;
    letter-spacing: 0; }
  &__value {
    margin-bottom: 24px;
    font-size: 12px;
    letter-spacing: 0;
    color: $text; }
  &__money {
    @include fm;
    font-size: 36px;
    line-height: (56/36);
    font-weight: 900;
    &_monthly {
      display: none; } }
  &__money.active {
    .pricing__money_monthly {
      display: inline; }
    .pricing__money_yearly {
      display: none; } }
  &__details {
    margin-bottom: auto; }
  &__line {
    display: flex;
    font-size: 14px;
    line-height: (24/14);
    letter-spacing: 0;
    color: $text;
    @include d {
      font-size: 16px;
      line-height: 1.5; }
    @include nl {
      margin-bottom: 24px; } }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    margin-right: 16px;
    font-size: 0;
    .icon-check {
      font-size: 22px; }
    .icon-arrow-prev {
      font-size: 16px; } }
  &__link {
    font-weight: 700;
    transition: border-color .25s;
    &:hover {
      border-color: transparent; } }
  &__btn {
    margin-top: 32px;
    @include t {
      padding: 0 16px; }
    @include m {
      min-width: 100%;
      margin-top: 40px; } } }

.accord {
  padding: 96px 48px 0;
  @include d {
    padding: 48px 48px 0; }
  @include t {
    padding: 32px 32px 0; }
  @include m {
    padding: 0; }
  & > &__title {
    text-align: center;
    @include t {
      padding: 0 85px; }
    @include m {
      padding: 0; } }
  &__info {
    max-width: 480px;
    margin: 0 auto 48px;
    text-align: center;
    font-size: 20px;
    line-height: 1.6;
    color: $text;
    @include m {
      margin-bottom: 40px;
      line-height: 2; } }
  &__list {
    padding-bottom: 32px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    @include t {
      padding: 0; }
    &::-webkit-scrollbar {
      display: none; } }
  &__section {
    @include nl {
      margin-bottom: 48px;
      @include m {
        margin-bottom: 40px; } } }
  &__section &__title {
    margin-bottom: 16px;
    @include m {
      font-size: 20px;
      line-height: 1.6; } }
  &__item {
    padding: 8px 0;
    border-top: 1px solid rgba($bl, .08);
    &:last-child {
      border-bottom: 1px solid rgba($bl, .08); } }
  &__head {
    position: relative;
    padding: 16px 40px 16px 0;
    font-size: 20px;
    line-height: 1.6;
    font-weight: 600;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    cursor: pointer;
    transition: opacity .25s;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 14px;
      height: 8px;
      background: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z' fill='%237737FF'/%3E%3C/svg%3E%0A") no-repeat 50% 50% / auto 100%;
      transition: transform .25s; }
    &:hover {
      opacity: .8; } }
  &__item.active &__head:before {
    transform: translateY(-50%) rotate(180deg); }
  &__body {
    display: none;
    padding-bottom: 16px;
    padding-right: 120px;
    font-size: 16px;
    line-height: 2;
    letter-spacing: 0;
    color: $text;
    @include d {
      padding-right: 0; } }
  &_faq &__title {
    margin-bottom: 48px;
    @include m {
      margin-bottom: 40px; } }
  &_faq &__list {
    max-height: 535px;
    @include m {
      max-height: 100%; } }
  &_work &__title {
    margin-bottom: 16px; }
  &_work &__list {
    max-height: 485px;
    @include m {
      max-height: 100%; } } }

body.dark {

  .header {
    &__pic {
      &_white {
        display: inline-block; }
      &_dark {
        display: none; } }
    &__wrap {
      @include t {
        background: $bl; } }
    &__link {
      color: $gray;
      @include t {
        color: $wh-dark; }
      &:hover {
        color: $green; } }
    &__login .header__text {
      color: $gray; }
    &__login:hover .header__text {
      color: $green; } }
  .main {
    &__title {
      &[data-aos] {
        color: $gray;
        &.aos-animate {
          color: $wh-dark; } } } }

  .info {
    color: $gray; }

  .btn_border {
    border-color: $bl-light;
    color: $wh-dark;
    &:hover {
      border-color: $wh-dark; } }

  .quality {
    &__item .quality__icon {
      background: $bl-light; } }

  .details {
    &__link {
      color: $wh-dark; } }

  .review {
    &__info {
      color: $gray; }
    .owl-carousel button.owl-dot {
      border-color: $bl;
      background: $wh;
      transition: all .25s;
      &.active {
        border-color: $wh;
        background: $bl; } } }

  .indicators {
    &__item .indicators__icon {
      background: $bl-light; } }

  .trial {
    &__wrap {
      background-color: $bl-light;
      background-image: url('../img/pattern-light.png');
      @include t {
        background: none; } } }

  .switch {
    &__box {
      background: $bl-light;
      &:before {
        border-color: $wh; } }
    &__sale {
      background: $bl-light;
      color: $wh-dark; } }

  .social {
    &__link {
      border-color: $bl-light;
      .icon {
        fill: $bg; }
      &:hover {
        border-color: $wh-dark; } } }

  .footer {
    &__pic {
      &_white {
        display: inline-block; }
      &_dark {
        display: none; } }
    &__link {
      color: $gray;
      &:hover {
        color: $green; } } }

  .popup {
    background: $bl;
    .mfp-close {
      border-color: $bl-light;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 14.2929C-0.0976311 14.6834 -0.0976311 15.3166 0.292893 15.7071C0.683418 16.0976 1.31658 16.0976 1.70711 15.7071L7.99999 9.41423L14.2929 15.7071C14.6834 16.0977 15.3166 16.0977 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L9.4142 8.00001L15.7071 1.70711C16.0976 1.31658 16.0976 0.683417 15.7071 0.292893C15.3166 -0.0976312 14.6834 -0.0976308 14.2929 0.292893L7.99999 6.5858L1.70711 0.292922C1.31658 -0.0976023 0.683419 -0.0976023 0.292895 0.292922C-0.0976295 0.683446 -0.0976295 1.31661 0.292895 1.70714L6.58577 8.00001L0.292893 14.2929Z' fill='%23ffffff'/%3E%3C/svg%3E%0A"); } }

  .mfp-bg {
    background: rgba($bl-light, .88); }

  .field {
    &__label {
      color: $gray; }
    &__input {
      border-color: $bl-light;
      color: $wh-dark;
      &:focus {
       border-color: $wh; } }
    &__icon {
      .icon {
        fill: $gray; } }
    &__input:focus + .field__icon .icon {
      fill: $wh; }
    &__link {
      color: $wh-dark; } }

  .pricing {
    &__info {
      color: $gray; }
    &__item {
      border-color: $bl-light; }
    &__line {
      color: $gray; } }

  .accord {
    &__info {
      color: $gray; }
    &__head:before {
      background: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A"); }
    &__body {
      color: $gray; } } }

[data-aos="animation-scale-y"] {
  transform: translateY(40px) scaleY(1.3);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateY(0) scaleY(1);
    opacity: 1; } }

[data-aos="animation-scale-y-and-color"] {
  transform: translateY(40px) scaleY(1.3);
  opacity: 0;
  color: $green;
  transition-property: transform, opacity, color;
  &.aos-animate {
    transform: translateY(0) scaleY(1);
    opacity: 1;
    color: $dark; } }


