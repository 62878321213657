.icon-arrow-prev {
  width: 1em;
  height: 1em;
  fill: #FAFBFF;
}
.icon-arrow-right {
  width: 0.57em;
  height: 1em;
  fill: #FAFBFF;
}
.icon-check {
  width: 1em;
  height: 1em;
  fill: #FAFBFF;
}
.icon-email {
  width: 1.22em;
  height: 1em;
  fill: #FAFBFF;
}
.icon-facebook {
  width: 0.59em;
  height: 1em;
  fill: #FAFBFF;
}
.icon-instagram {
  width: 1em;
  height: 1em;
  fill: #FAFBFF;
}
.icon-lock {
  width: 0.91em;
  height: 1em;
  fill: #FAFBFF;
}
.icon-twitter {
  width: 1.22em;
  height: 1em;
  fill: #FAFBFF;
}
